import { ColumnLayout, Container, Header, Link } from '@amzn/awsui-components-react/polaris';
import React from 'react';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';
import { renderField } from './brokerInstanceInfoTable';
import { DateTime } from 'luxon';
import { Broker, BrokerConfigEntry, BrokerEngineType, BrokerMaintenanceInfo } from '@amzn/amazonmq-opsconsole-client';


function renderConfiguration(attributeName: string, configEntry: BrokerConfigEntry) {

    if (!configEntry) {
        return (
            <div key={attributeName}>
                <div className="awsui-util-label">
                    {attributeName}
                </div>
                <div className="awsui-util-label">None</div>
            </div>
        )
    }

    let linkTitle = configEntry.configurationId + " | " + configEntry.revision;
    let isConfigInvalid = configEntry.invalid !== null ? configEntry.invalid : false;
    if (isConfigInvalid) {
        linkTitle = linkTitle + " - invalid";
    } else {
        linkTitle = linkTitle + " - ✔";
    }
    return (
        <div key={attributeName}>
            <div className="awsui-util-label">
                {attributeName}
                {configEntry &&
                    <CopyToClipboardButton text={configEntry.isenLink} textDescription={attributeName} />
                }
            </div>
            <div className="awsui-util-label">
                <Link target="_blank" href={configEntry.isenLink}>{linkTitle}</Link>
                <CopyToClipboardButton text={configEntry.isenLink}  />
            </div>
        </div>
    )
}

type Props = {
    brokerMaintenanceInfo: BrokerMaintenanceInfo
    broker: Broker
}

const BrokerMaintenanceInfoTable : React.FC<Props> = ({brokerMaintenanceInfo, broker}) => {
    let content : React.ReactElement[] = [];
    const localDate = new Date();
    let nextDate: Date | null = null;
    const parsedMW = parseMaintenanceWindowStartTime(brokerMaintenanceInfo.maintenanceWindow);
    if(parsedMW){
        nextDate = getNextMWDate(broker.id, localDate, parsedMW );
    }
    content.push(renderConfiguration("Current Configuration", brokerMaintenanceInfo.currentConfiguration));
    content.push(renderConfiguration("Pending Configuration", brokerMaintenanceInfo.pendingConfiguration));
    content.push(renderField("Maintenance Window", brokerMaintenanceInfo.maintenanceWindow));
    content.push(renderField("Next Maintenance", brokerMaintenanceInfo.nextMaintenance));
    content.push(renderField("Maintenance Flag", brokerMaintenanceInfo.maintenanceFlag ? "true" : "false"));
    // Next CFN MW should be insync with `SchedulePatchingConditionRetriever`. Modify this accordingly.
    // TODO: Get Next CFN MW from `SchedulePatchingConditionRetriever`
    if (broker.summary.brokerEngineType === BrokerEngineType.RABBITMQ) {
        content.push(renderField("Next CFN MW", nextDate ? nextDate.toUTCString() : 'Condition not met'));
    }
    content.push(renderField("Auto Minor Version Upgrade", brokerMaintenanceInfo.autoMinorVersionUpgrade ? "true" : "false"));
    return (
        <Container header={
            <Header variant='h2'>
                Broker Maintenance Info
            </Header>
        }>
            <ColumnLayout columns={3} variant="text-grid">
                {content}
            </ColumnLayout>
        </Container>
    )
}

const getWeekOfEpoch = (date: Date): number => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const epoch = new Date(1970, 0, 1); // January 1, 1970
    const daysSinceEpoch = (date.getTime() - epoch.getTime()) / millisecondsPerDay;
    return Math.floor(daysSinceEpoch / 7);
};

const getNextMWDate = (brokerId: string, startDate: Date, maintenanceWindow: { dayOfWeek: string, timeOfDay: string, timeZone: string }): Date | null => {
    const dayOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const targetDay = dayOfWeek.indexOf(maintenanceWindow.dayOfWeek.toLowerCase());

    const startUtcDate = convertToUtc(maintenanceWindow.timeOfDay, maintenanceWindow.timeZone, startDate);
    let date = new Date(startUtcDate);

    const daysToNextTargetDay = (targetDay + 7 - date.getDay()) % 7 || 7;

    date.setUTCDate(date.getUTCDate() + daysToNextTargetDay);
    
    let weekOfEpoch = getWeekOfEpoch(date);
    let charValue = parseInt(brokerId.charAt(2), 16);

    // Ensure the date is at least 3 weeks apart from the start date if necessary
    if (charValue % 3 !== weekOfEpoch % 3) {
        const weeksToAdd = (charValue % 3 - weekOfEpoch % 3 + 3) % 3;
        date.setUTCDate(date.getUTCDate() + weeksToAdd * 7);
    }

    return date;

};

const parseMaintenanceWindowStartTime = (timeString: string) => {
    const cleanedString = timeString.replace(/MaintenanceWindowStartTime\{|\}/g, '').trim();
    const params = cleanedString.split(',').map(param => param.trim().split('='));
    const dayOfWeek = params.find(param => param[0] === 'dayOfWeek')?.[1];
    const timeOfDay = params.find(param => param[0] === 'timeOfDay')?.[1];
    const timeZone = params.find(param => param[0] === 'timeZone')?.[1];

    if (dayOfWeek && timeOfDay && timeZone) {
        return {
            dayOfWeek,
            timeOfDay,
            timeZone,
        };
    }
    return null;
};

const convertToUtc = (timeOfDay: string, timeZone: string, date: Date): Date => {
    const [hour, minute] = timeOfDay.split(':').map(Number);
    const time = DateTime.fromObject({ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate(), hour, minute },
        { zone: timeZone }
    );

    return new Date(time.toUTC().toISO() as string);
};



export default BrokerMaintenanceInfoTable;
