import { Container, Spinner, Tabs } from '@amzn/awsui-components-react/polaris';
import React from 'react';
import BrokerMetricsTab from './BrokerMetricsTab';
import BrokerStorageTab from './BrokerStorageTab';
import EC2MetricsTab from './EC2MetricsTab';
import RabbitChurnAndRatesTab from './RabbitChurnAndRatesTab';
import RabbitQueuesTab from './RabbitQueuesTab';
import { Broker, BrokerEngineType, EC2Instance } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    broker: Broker,
    brokerInstances: EC2Instance[] | undefined
}

const ServiceMetrics : React.FC<Props> = ({broker, brokerInstances}) => {
    
    if (brokerInstances === undefined) {
        return <Container>
            <Spinner />
        </Container>
    }

    return (
            <div>
                {broker.summary.brokerEngineType === BrokerEngineType.RABBITMQ &&
                    <Tabs
                        variant="container"
                        tabs={[
                            {
                                id: 'overview',
                                label: 'Broker Metrics',
                                content:  <BrokerMetricsTab broker={broker} brokerInstances={brokerInstances}/>
                            },
                            {
                                id: 'instances',
                                label: 'Instance Metrics',
                                content:  <EC2MetricsTab broker={broker} brokerInstances={brokerInstances}/>
                            },
                            {
                                id: 'rates',
                                label: 'Churn & Rates',
                                content:  <RabbitChurnAndRatesTab broker={broker} />
                            },
                            {
                                id: 'queues',
                                label: 'Queues',
                                content:  <RabbitQueuesTab broker={broker} brokerInstances={brokerInstances} />
                            }
                    ]}/>
                }
                {broker.summary.brokerEngineType === BrokerEngineType.ACTIVEMQ &&
                    <Tabs
                        variant="container"
                        tabs={[
                            {
                                id: 'overview',
                                label: 'Broker Metrics',
                                content:  <BrokerMetricsTab broker={broker} brokerInstances={brokerInstances} />
                            },
                            {
                                id: 'instances',
                                label: 'Instance Metrics',
                                content:  <EC2MetricsTab broker={broker} brokerInstances={brokerInstances}/>
                            },
                            {
                                id: 'broker-storage',
                                label: 'Broker Storage',
                                content:  <BrokerStorageTab broker={broker} />
                            }
                    ]}/>
                }
            </div>
    )

}

export default ServiceMetrics;