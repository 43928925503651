import React, { useEffect, useState } from 'react';
import { ColumnLayout, Container, Header, Link, Spinner } from '@amzn/awsui-components-react/polaris';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';
import { getRabbitBrokerMemoryBreakdown, getRabbitClusterStatus } from '../../../api/api';
import { renderLink } from '../../Components/tableUtils';
import BrokerInstanceMemoryBreakdownPanel from '../../Components/MemoryBreakdown/BrokerInstanceMemoryBreakdownPanel';
import { Broker, BrokerEngineType, BrokerInstanceMemoryBreakdown, BrokerMemoryBreakdown, EC2Instance } from '@amzn/amazonmq-opsconsole-client';
import { ClusterStatus, ClusterStatusAlarm } from '../../../types';

type Props = {
    broker: Broker,
    brokerInstances: EC2Instance[] | undefined
}

export function renderEmpty(attributeName: string, placeholder: string = "None") {
    return (
        <div className="awsui-util-label" style={{margin: 0}}>
            {attributeName}:
            <span>&nbsp;{placeholder}</span>
        </div>
    )
}

export function renderField(attributeName: string, attributeValue: string) {
    return (
        <div className="awsui-util-label" style={{margin: 0}}>
            {attributeName}:
            <span>&nbsp;{attributeValue}</span>
            <CopyToClipboardButton text={attributeValue}/>
        </div>
    )
}

export function renderInstanceTitle(title: string) {
    return (
        <div className="awsui-util-label" style={{margin: 0}}>
            {title}:
            <CopyToClipboardButton text={title}/>
        </div>
    )
}

export function renderIsenLinks(ec2IsenLink: string, asgIsenLink: string) {
    return (
        <div className="awsui-util-label" style={{margin: 0, display: 'flex', gap: 8}}>
            <Link target="_blank" href={ec2IsenLink}>EC2 Isen Link</Link>
            {asgIsenLink &&
                <Link target="_blank" href={asgIsenLink}>ASG Isen Link</Link>
            }
        </div>
    )
}

function renderInstanceInfo(instance: EC2Instance) {
    let content: React.ReactElement[] = [];
    content.push(renderInstanceTitle(instance.title));
    content.push(renderIsenLinks(instance.ec2IsenLink, instance.asgIsenLink));
    content.push(renderField("Public IP", instance.publicIp));
    content.push(renderField("Private IP", instance.privateIp));
    content.push(renderField("CPU Credits", instance.cpuCredits));
    content.push(renderField("Broker Status", instance.brokerStatus));
    content.push(renderField("Agent Status", instance.agentStatus));
    content.push(renderField("Data Partition Avail.", instance.dataPartitionAvailability + ""));
    content.push(renderField("Launch Time", instance.launchTime));
    content.push(renderField("Availability Zone", instance.availabilityZone));

    if (instance.volumes.length > 0) {
        let rootPartition = instance.volumes[0];
        let linkName = rootPartition.volumeId + " (" + rootPartition.size + " GB)";
        content.push(renderLink("EBS Root Partition", linkName, rootPartition.ebsStatsLink));
    } else {
        content.push(renderEmpty("EBS Root Partition"));
    }

    if (instance.volumes.length > 1) {
        let dataPartition = instance.volumes.find(v => v.size != 32);
        if (dataPartition !== undefined) {
            let linkName = dataPartition.volumeId + " (" + dataPartition.size + " GB)";
            content.push(renderLink("EBS Data Partition", linkName, dataPartition.ebsStatsLink));
        } else {
            content.push(renderEmpty("EBS Data Partition", "None (EFS broker)"));
        }
    }

    content.push(renderField("ENI ID", instance.eniId));
    content.push(renderField("Scheduled for Retirement", instance.scheduledForRetirement ? "true" : "false"))
    return (
        <div>
            {content}
        </div>
    )
}

const BrokerInstanceInfoTable : React.FC<Props> = ({broker, brokerInstances}) => {

    const [rabbitMqClusterStatusAlarms, setRabbitMqClusterStatusAlarms] = useState<ClusterStatusAlarm[] | undefined>(undefined);
    const [loadingClusterStatus, setLoadingClusterStatus] = useState<boolean>(false);
    const [clusterStatusError, setClusterStatusError] = useState<boolean>(false);

    const [memoryBreakdown, setMemoryBreakdown] = useState<BrokerMemoryBreakdown | undefined>(undefined);
    const [memoryBreakdownLoading, setMemoryBreakdownLoading] = useState<boolean>(false);
    const [memoryBreakdownError, setMemoryBreakdownError] = useState<boolean>(false);

    useEffect(() => {
        if (broker.summary.brokerEngineType === BrokerEngineType.RABBITMQ) {
            // only available for Rabbit.

            setLoadingClusterStatus(true);
            setClusterStatusError(false);
            getRabbitClusterStatus(broker.id).then(response => {
                setRabbitMqClusterStatusAlarms((response.clusterStatus as ClusterStatus).alarms); // extract the "alarms" field from response
            }).catch(error => {
                console.error(error);
                setLoadingClusterStatus(false);
                setClusterStatusError(true);
                return;
            }).finally(() => {
                setLoadingClusterStatus(false);
            });

            setMemoryBreakdownLoading(true);
            setMemoryBreakdownError(false);
            getRabbitBrokerMemoryBreakdown(broker.id).then(response => {
                setMemoryBreakdown(response.memoryBreakdown);
            }).catch(error => {
                console.error(error);
                setMemoryBreakdownError(true);
            }).finally(() => {
                setMemoryBreakdownLoading(false);
            })
        }
    }, [broker.id])

    function renderClusterStatusAlarms(instance: EC2Instance) {
        // If GetClusterStatusLambda's result's alarm field is populated, then display MEMORY ALARM.
        // Else, display nothing.
        if (rabbitMqClusterStatusAlarms?.length) {
            // Here, we try to match the alarms to the instances 

            // Convert instance.privateIp from x.x.x.x to x-x-x-x, and search for it in cluster_status "alarms" value
            // The "node" field of an alarm entry will look something like "rabbit@ip-x-x-x-x.us-west-2.compute.internal"
            const hyphenPrivateIp = instance.privateIp.split(".").join("-");
            const matchedAlarms = rabbitMqClusterStatusAlarms.filter(alarm => {
                return alarm.node.includes(hyphenPrivateIp);
            });

            if (matchedAlarms) {
                // If we find alarms that match, we display them.
                // There are (at least) 3 alarm types; memory, disk space, and file descriptor.
                // Else, display nothing
                return (
                    <div>
                        {matchedAlarms.map((alarm: ClusterStatusAlarm) => {
                            return (
                                <div className="awsui-util-label" style={{margin: 0, color: 'red'}}>
                                    {alarm.resource === "memory" ? "MEMORY ALARM" : "MISC ALARM"}
                                    <span>&nbsp;{}</span>
                                    <CopyToClipboardButton text={JSON.stringify(rabbitMqClusterStatusAlarms)}/>
                                </div>
                            );
                        })}
                    </div>
                );
            }
        }
        return null;
    }
   
    return (
        <Container header={
            <Header variant="h2">
                Broker Instance Info
            </Header>
        }>
            {brokerInstances === undefined &&
                <Spinner />
            }
            {brokerInstances !== undefined &&
                <ColumnLayout columns={3} variant="text-grid">
                        {brokerInstances.map((i: EC2Instance) => {
                            let instanceMemoryBreakdown : BrokerInstanceMemoryBreakdown | undefined;
                            if (memoryBreakdown !== undefined) {
                                let instanceBreakdown : any = memoryBreakdown.instances;
                                instanceMemoryBreakdown = instanceBreakdown[i.instanceId];
                            }
                            return (
                                <div key={`instance-${i.instanceId}`}>
                                    {loadingClusterStatus && <Spinner /> }
                                    {clusterStatusError &&
                                        <span className="awsui-util-label" style={{color: 'red'}}>Could not load cluster status alarms</span>
                                    }
                                    {!clusterStatusError && !loadingClusterStatus && renderClusterStatusAlarms(i)}

                                    {renderInstanceInfo(i)}

                                    {memoryBreakdownLoading && <span className="awsui-util-label" style={{display: 'flex', alignItems: 'center', gap: 8}}>Loading memory breakdown.. <Spinner /> </span>}
                                    {memoryBreakdownError && 
                                        <span className="awsui-util-label" style={{color: 'red'}}>Could not load memory breakdown</span>
                                    }
                                    {!memoryBreakdownError && instanceMemoryBreakdown !== undefined &&
                                        <BrokerInstanceMemoryBreakdownPanel instance={i} instanceMemoryBreakdown={instanceMemoryBreakdown} /> 
                                    }
                                </div>
                            )
                        })}
                </ColumnLayout>
            }
        </Container>
    )   
}

export default BrokerInstanceInfoTable;