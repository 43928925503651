import { Broker, QualifiedFailoverEvent } from "@amzn/amazonmq-opsconsole-client";

export const QUEUE_TYPES = [
    "classic-empty",
    "classic",
    "classic-autodelete-empty",
    "classic-exclusive",
    "classic-exclusive-autodelete-empty",
    "quorum"
];

export const ALL_CLASSIC_QUEUE_STATES = [
    "fullySynced",
    "partiallySynced",
    "noSyncedMirrors",
    "missingStateNoSyncedMirrors",
    "missingStatePartiallySynced",
    "missingStateFullySynced",
    "missingStateNoPrimary",
    "unidentified",
    "syncing",
    "down",
    "stopped",
    "crashed",
    "flow"
];

export const ALL_QUORUM_QUEUE_STATES = [
    "allOnline",
    "quorumOnline",
    "minorityOnline",

    "allOnlineWrongSize",
    "quorumOnlineWrongSize",
    "minorityOnlineWrongSize",

    "missingStateAllOnline",
    "missingStateQuorumOnline",
    "missingStateMinorityOnline",

    "missingStateAllOnlineWrongSize",
    "missingStateQuorumOnlineWrongSize",
    "missingStateMinorityOnlineWrongSize",

    "unsupportedQueueTypes",
    "unidentified",
    "down",
    "stopped",
    "flow",
];

export const ALL_DOMAINS: Domain[] = [
    {
        "label": "Beta",
        "id": "beta"
    },
    {
        "label": "Gamma",
        "id": "gamma"
    },
    {
        "label": "Prod",
        "id": "prod"
    }
]

export enum VolumeType {
    ROOT_VOLUME = "ROOT_VOLUME",
    DATA_VOLUME = "DATA_VOLUME"
}

// Mapping from instanceId -> privateIp
export type NodeMapping = {
    [instanceId: string]: string;
}

export type Dashboard = {
    label: string;
    id: string;
}

export type Config = {
    username: string
}

export type Domain = {
    label: string,
    id: string
}

export type Link = {
    name: string;
    href: string;
}

export type Region = {
    regionCode: string;
    airportCode: string;
}

export type BrokerEFS = {
    id: string;
}

export type BrokerAlarm = {
    name: string;
    link: string;
}

export enum DRMode {
    NONE = "NONE",
    CRDR = "CRDR"
}

export enum ReplicationRole {
    PRIMARY = "PRIMARY",
    RECOVERY = "RECOVERY"
}

export type DrCounterPart = {
    region: string;
    brokerId: string;
}

export type BrokerInstanceMemoryBreakdownUnit = {
    bytes: number,
    percentage: number
}

export type BrokerInstanceMemoryBreakdown = {
    status: "SUCCESS" | "FAILED",
    memoryBreakdown: Map<string, BrokerInstanceMemoryBreakdownUnit>
}

export type ClusterStatus = {
    clusterStatus: {
        alarms: ClusterStatusAlarm[];
        cluster_name: string;
        cpu_cores: ClusterStatusCpuCore[];
        disk_nodes: string[];
        feature_flags: ClusterStatusFeatureFlag[];
        listeners: ClusterStatusListener[];
        maintenance_status: ClusterStatusMaintenanceStatus[];
        partitions: any;
        ram_nodes: any;
        running_nodes: string[];
        versions: ClusterStatusVersion[];
    },
    alarms: ClusterStatusAlarm[]
}

export type ClusterStatusAlarm = {
    node: string;
    resource: string;
    type: string;
}

export type ClusterStatusCpuCore = {
    [key: string]: number;
}

export type ClusterStatusFeatureFlag = {
    desc: string;
    doc_url: string;
    name: string;
    provided_by: string;
    stability: string;
    state: string;
}

export type ClusterStatusListener = {
    [key: string]: {
        interface: string;
        node: string;
        port: number;
        protocol: string;
        purpose: string;
    }
};

export type ClusterStatusMaintenanceStatus = {
    [key: string]: string;
};

export type ClusterStatusVersion = {
    [key: string]: {
        erlang_version: string;
        rabbitmq_name: string;
        rabbitmq_version: string;
    }
}

export type RabbitBrokerConfigOverride = {
    brokerId: string,
    config: string
}

export type RabbitBrokerPolicies = {
    brokerPolicies: {
        policies: {
            vhost: string,
            name: string,
            pattern: string,
            applyTo: string,
            definition: string,
            priority: number
        },
        operatorPolicies: {
            vhost: string,
            name: string,
            pattern: string,
            applyTo: string,
            definition: string,
            priority: number
        }
    }
}

export type SimMarkdown = {
    markdown: string
}

export type ReachoutTemplate = {
    templateId: string,
    description: string,
    title: string
}

export type ReachoutTemplateList = {
    reachoutTemplates: ReachoutTemplate[]
}
export type FailoverEvent = {
    arcUpdateTime: number,
    initiationTime: number,
    completionTime: number,
    mode: string,
    roleAfterFailover: string
}

export type QualifiedFailoverEventList = {
    failoverHistory: QualifiedFailoverEvent[]
}

export type CustomerMetricName = {
    type: string,
    name: string
}

export type CustomerMetricNamesList = {
    customerMetricNames: CustomerMetricName[]
}

export type CustomerMetricData = {

}

export type CloudwatchDashboardLink = {
    link: string
}

export type ApplicationState = {
    currentBroker: Broker | undefined,
    setCurrentBroker: (broker: Broker | undefined) => void
}

export type BrokerMemoryBreakdown = {
    instances: Map<string, BrokerInstanceMemoryBreakdown>
}

export type BrokerEnvironment = {
    brokerEnvironment: object
}

export type RabbitBrokerQueue = {
    name: string,
    type: string,
    state: string,
    vhost: string,
    primaryNode: string
}

export type RabbitBrokerQueues = {
    queues: RabbitBrokerQueue[]
}

export type BrokerInstance = {
    instanceId: string
}